import { booleanAttribute, ChangeDetectorRef, Component, inject, Injector, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel, Validators } from '@angular/forms'
import { tap } from 'rxjs'

export type BaseFormControlComponentDeps = [ Injector, ChangeDetectorRef ]

@Component({
  template: ''
})
export abstract class BaseFormControlComponent implements OnInit, ControlValueAccessor {

  @Input({ required: true }) label: string = ''
  @Input({ transform: booleanAttribute }) disabled: boolean = false
  @Input({ transform: booleanAttribute }) readonly: boolean = false
  @Input({ transform: booleanAttribute }) showPlaceholder: boolean = false
  @Input({ transform: booleanAttribute }) hideErrorMessages: boolean = false
  @Input({ transform: booleanAttribute }) returnId: boolean = false
  @Input({ transform: booleanAttribute }) disableControl: boolean = false
  @Input() placeholder: string = ''
  @Input() tooltip: string = ''
  @Input() appendTo: string = 'body'

  protected _value: any
  protected id: string
  protected control!: FormControl
  protected onChange: any = (value: any) => {}
  protected onTouched: any = () => {}

  constructor(
    private injector: Injector,
    protected cd: ChangeDetectorRef,
  ) {
    this.id = Math.random().toString(36).substring(2)
  }

  get required(): boolean {
    return this.control && (this.control.hasValidator(Validators.required) || this.control.hasError('required'))
  }

  get invalid(): boolean {
    return this.control && this.control.invalid
  }

  get _placeholder(): string {
    const appendix: string = this.required ? '*' : ''
    return !this.showPlaceholder ? '' : (this.placeholder === '' ? this.label + appendix : this.placeholder + appendix)
  }

  set value(val: any) {
    this._value = val
    this.onChange(val)
  }

  get value() {
    return this._value
  }

  public ngOnInit(): void {
    this.init()

    this.setComponentControl()
  }

  protected init(): void {}

  writeValue(value: any): void {
    this.value = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
  }

  private setComponentControl(): void {
    // disable control if it's not necessary (e.g. AddressSelect in AddressForm)
    if (this.disableControl) return

    let injectedControl

    try {
      injectedControl = this.injector.get(NgControl)
    }
    catch (error) {
      console.error('You must provide [(ngModel)] or formControlName to this form control component', this)
    }

    if (!injectedControl) return

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel

        this.control = control

        this.control.valueChanges
          .pipe(
            tap((value: any) => update.emit(value)),
            //takeUntilDestroyed(),
          )
          .subscribe()
        break
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName)
        break
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as FormControl
        break
      }
    }
  }

  static deps(): BaseFormControlComponentDeps {
    const injector: Injector = inject(Injector)
    const cd = inject(ChangeDetectorRef)

    return [ injector, cd ]
  }

}
