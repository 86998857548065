import { Component, Input } from '@angular/core'
import { NgClass, NgIf } from '@angular/common'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'tb-control-label',
  standalone: true,
  template: `
    <label
      *ngIf="label.length > 0"
      [for]="id"
      [ngClass]="{ 'ng-invalid': invalid }"
      class="block text-500 font-medium mb-2"
    >
      <span [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition">
        {{ label + (required ? '*' : '') }}
        <i class="pi pi-info-circle" style="font-size:1rem" *ngIf="tooltip.length > 0"></i>
      </span>
    </label>`,
  imports: [
    NgClass,
    TooltipModule,
    NgIf
  ],
})
export class ControlLabelComponent {

  @Input({ required: true }) label: string = ''
  @Input() required: boolean = false
  @Input() invalid: boolean = false
  @Input() id: string = ''
  @Input() tooltip: string = ''
  @Input() tooltipPosition: string = 'left'

}
